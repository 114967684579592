
import { defineComponent } from "vue";
import productCard from "@/components/common/productCard.vue";
import useFootPrint from "@/hooks/useFootPrint";
import dateFormat from "@/utils/dateFormat";
import Empty from "@/components/common/Empty.vue";
export default defineComponent({
  name: "FootPrint",
  components: {
    productCard,
    Empty,
  },
  setup() {
    const { getFootItem, footQueue } = useFootPrint();
    getFootItem();
    const realData = [];
    const data = footQueue.value();
    for (let index = data.length - 1; index >= 0; index--) {
      const item: any = data[index];
      if (realData.length) {
        if (realData[realData.length - 1].date == item.addtime) {
          realData[realData.length - 1].data.push(item);
        } else {
          realData.push({
            date: item.addtime,
            data: [item],
          });
        }
      } else {
        realData.push({
          date: item.addtime,
          data: [item],
        });
      }
    }

    return {
      realData,
      dateFormat,
    };
  },
});
